<template>
  <div class="container">
    <h2>Relatório uso do Terminal SmartScreening</h2>
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>Dia</th>
        <th>Quantidade</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(value, key) in pacients" :key="key">
        <td>{{ key }}</td>
        <td>{{ value }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export async function getAll() {

  const response = await fetch('/api/list');
  return await response.json();
}
export default {
  name: 'HelloWorld',
  data() {
    return {
      pacients: []
    }
  },
  mounted () {
    getAll().then(response => {
      console.log(response)
      this.pacients = response
    })
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
table {
  border: 2px solid #42b983;
  border-radius: 3px;
  background-color: #fff;
}

th {
  background-color: #42b983;
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
  user-select: none;
}

td {
  background-color: #f9f9f9;
}

th,
td {
  min-width: 120px;
  padding: 10px 20px;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}
</style>